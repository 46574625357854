import { graphql, Link } from "gatsby"
import React from "react"
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../components/seo'
import NewsLists from '../templates/newlists';
import Layout from '../components/Layout'
const baseuri = "https://dmf5.xyz"
const baseuridemo = "http://admin.hovinx.com"

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle} ${curr === 1 ? '' : ` - Page ${curr}`}`}
          description={`${siteDescription} ${curr === 1 ? '' : ` - Page ${curr}`}`}
          canonical={`https://${domainName}`}
        />
        <ul className="row feature-list feature-list-sm">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <NewsLists key={node.slugtitle} node={node} />
            )
          })}
          <li className="col-12 text-center w-pagination">
            <ul
            >
              {!isFirst && (
                <li>
                  <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev" className="w-pagination__link">
                    ←
                </Link>
                </li>
              )}
              {Array.from({ length: numPages }, (_, i) => {
                if (i < curr + 3 && i > curr - 3) {
                  return (
                    <li
                      key={`pagination-number${i + 1}`}
                      className="w-pagination__link"
                    >
                      <Link
                        to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                        style={{
                          textDecoration: 'none',
                          color: i + 1 === curr ? '#000' : '',
                          background: i + 1 === curr ? '#fff' : '',
                        }}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  )
                }
              })}
              {!isLast && (
                <li className="w-pagination__link">
                  <Link to={`/page/${nextPage}`} rel="next">
                    →
                </Link>
                </li>
              )}
            </ul>
          </li>
        </ul>
        {/* <div className="w-pagination">
          <ul
          >
            {!isFirst && (
              <li className="w-pagination__link">
                <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPages }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="w-pagination__link"
                  >
                    <Link
                      to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                      style={{
                        textDecoration: 'none',
                        color: i + 1 === curr ? '#000' : '',
                        background: i + 1 === curr ? '#fff' : '',
                      }}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="w-pagination__link">
                <Link to={`/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div> */}
      </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 21, $skip: Int = 0, $siteId: Int = 1051) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip,
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
